import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { immutableGet, rawJs } from '../../common/selectorTools'
import { actions, selectors } from '../../ducks/control'
import styled from 'styled-components'
import { message, Popconfirm, Spin } from 'antd'
import { vw, vh } from 'common/utils'
import { BgBox, MiniScreen, FlowBtn, SignInPage, ProjectGroupList, Rank } from 'components/control'

const FlowBox = styled.div`
    // width: 390px;
    display: flex;
    flex-wrap: wrap;
`

class NewControlPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timestamp: new Date().getTime(),
            currentEvent: null,
            flowList: [
                { title: '开屏', event: 'INIT', clickFunc: this.handleShowStartScreen },
                { title: '测试打分', event: 'TEST' },
                { title: '项目列表', event: 'PROGRAM_LIST' },
                { title: '签到', event: 'SIGN_IN' },
                { title: '排名', event: 'RANK' }
            ],
            signInShowType: null,
            subItemList: [],
            signInLoading: false,
            currentProjects: []
        }
    }

    componentDidMount() {
        const { match: { params: { competitionId } } } = this.props
        const search = window.location.search
        const params = new URLSearchParams(search)
        const tokenUrl = params.get('token')
        const token = localStorage.getItem(`score-${competitionId}-token`)
        if (tokenUrl !== null && tokenUrl !== undefined) {
            if (token) {
                localStorage.removeItem(`score-${competitionId}-token`)
            }
            localStorage.setItem(`score-${competitionId}-token`, decodeURIComponent(tokenUrl))
            window.location.replace(`/web/control/panel/${competitionId}`)
        } else if (token) {
            this.fetchMathInfo()
        } else {
            this.props.history.push(`/web/control/login/${competitionId}`)
        }
    }
    // 比赛信息
    fetchMathInfo = () => {
        const { match: { params: { competitionId } } } = this.props
        this.props.controlGetCurrentMatch(
            { competitionId },
            () => {
                const { matchInfo: { link, linkData, programsMap, activeProgramId, activeItemId, setting: { mode } } } = this.props
                if (['INIT', 'RANK', 'PROGRAM_LIST', 'TEST'].includes(link)) {
                    this.setState({
                        currentEvent: link
                    })
                } else if (link === 'SIGN_IN') {
                    this.setState({
                        currentEvent: link,
                        signInShowType: linkData.signInShowType
                    })
                } else {
                    const currentProjects = []
                    let currentEvent = null
                    if (activeProgramId && programsMap[activeProgramId]) {
                        currentProjects.push({ ...programsMap[activeProgramId], event: link })
                    }
                    if (mode === 'BATTLE' && activeItemId && programsMap[activeItemId]) {
                        currentProjects.push({ ...programsMap[activeItemId], event: link })
                    }
                    if (['SPEECH', 'SCORE', 'SHOW_SCORE', 'SHOW_RESULT', 'BATTLE', 'PAUSE', 'RESUME', 'DETERMINE'].includes(link)) {
                        currentEvent = 'PROGRAM_LIST'
                    } else if (link.indexOf('TEST') !== -1) {
                        currentEvent = 'TEST'
                    }
                    this.setState({
                        currentEvent,
                        currentProjects
                    })
                }
            },
            (err) => {
                this.setState({ loading: false })
                this.handleOnFail(err)
            }
        )
    }
    // 环节切换
    handleChangeFlow = (clickFunc, event) => {
        this.props.controlSetCurrentMatchLinkData({})
        this.setState({
            currentEvent: event,
            signInShowType: null
        })
        if (clickFunc) {
            clickFunc()
        }
        if (['PROGRAM_LIST', 'TEST'].includes(event)) {
            const { match: { params: { competitionId } } } = this.props
            this.props[event === 'TEST' ? 'controlGetTestList' : 'controlGetProjectList'](
                { competitionId },
                () => {
                },
                (err) => {
                    message.error('环节切换错误')
                }
            )
        } else {
            this.setState({
                currentProjects: []
            })
        }
    }
    // 开屏
    handleShowStartScreen = () => {
        const { match: { params: { competitionId } } } = this.props
        this.props.controlControlShowMatchPage(
            { competitionId },
            () => { },
            this.handleOnFail
        )
    }
    // 签到列表
    fetchSignInData = () => {
        const { match: { params: { competitionId } } } = this.props
        this.setState({ signInLoading: true })
        this.props.controlGetSignInData(
            competitionId,
            () => {
                this.setState({ signInLoading: false })
            },
            () => {
                this.setState({ signInLoading: false })
            }
        )
    }
    // 签到
    handleSignIn = (signInShowType) => {
        const { match: { params: { competitionId } } } = this.props
        this.setState({ signInLoading: true })
        this.props.controlSignIn(
            { competitionId, signInShowType },
            () => {
                this.setState({
                    signInShowType,
                    signInLoading: false
                })
            },
            (err) => {
                this.setState({ signInLoading: false })
            }
        )
    }
    // 项目操作
    handleEventOnSuccess = ({ event, currentProjects }) => {
        this.setState({ currentProjects: [...currentProjects], event })
    }
    // 宣讲
    handleOnSpeech = (payload, callback) => {
        this.props.controlProjectOnSpeech(
            payload,
            () => {
                callback && callback()
            },
            this.handleOnFail
        )
    }

    handleOnFail = (err) => {
        if (!err) {
            message.error('未知错误')
            return
        }
        if (err.response) {
            if (err.response.status === 401) {
                const { match: { params: { competitionId } } } = this.props
                message.error('登录失效 请重新登录')
                localStorage.removeItem(`score-${competitionId}-token`)
                this.props.history.push(`/web/control/login/${competitionId}`)
            } else {
                const detail = err.response && err.response.data && err.response.data.detail ? err.response.data.detail : '无'
                const status = err.response && err.response.status ? err.response.status : '无'
                message.error(`操作失败 错误码：${status}${detail ? ` 原因：${detail}` : ''}`)
            }
        }
    }

    render() {
        const { signInData, matchInfo = {} } = this.props
        const {
            flowList,
            currentEvent,
            timestamp,
            signInShowType,
            currentProjects
        } = this.state
        const { setting = {} } = matchInfo
        const bsaeUri = process.env.NODE_ENV === 'development' ? window.location.origin : process.env.REACT_APP_API_BASE_URI
        const { match: { params: { competitionId } } } = this.props
        const projectGroupPayload = {
            competitionId,
            currentProjects,
            eventOnSuccess: this.handleEventOnSuccess
        }
        const eventChildren = {
            'INIT': (
                <div
                    style={{
                        height: '100%',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: vw(48)
                    }}
                >
                    正在展示开屏页
                </div>
            ),
            'TEST': (
                <ProjectGroupList
                    key={'test-list'}
                    isTest={true}
                    {...projectGroupPayload}
                />
            ),
            'PROGRAM_LIST': (
                <ProjectGroupList
                    key={'list'}
                    isTest={false}
                    {...projectGroupPayload}
                />
            ),
            'SIGN_IN': (
                <SignInPage
                    spinning={this.state.signInLoading}
                    title={matchInfo.name || '比赛名称'}
                    onChange={this.handleSignIn}
                    data={signInData}
                    getSignInData={this.fetchSignInData}
                    signInShowType={signInShowType}
                />
            ),
            'RANK': (
                <Rank
                    competitionId={competitionId}
                // getData={this.props.controlGetProjectList}
                />
            ),
        }
        // todo 倒计时 时间结束自动判胜(同分情况根据先得分标识判断) 暂停中断以及继续
        return (
            <BgBox title={matchInfo.name}>
                <div style={{ width: 420 }}>
                    <MiniScreen
                        src={`${bsaeUri}/web/match/${competitionId}?timestamp=${timestamp}`}
                        screenUrl={`${bsaeUri}/web/match/${competitionId}`}
                        current={currentProjects}
                    />
                    <FlowBox>
                        {flowList.map(({ title, event, clickFunc }) => (
                            <FlowBtn
                                key={event}
                                text={title}
                                event={event}
                                active={currentEvent === event ? true : false}
                                style={{ marginRight: 24, marginBottom: 20 }}
                                onClick={this.handleChangeFlow.bind(this, clickFunc)}
                                visible={!(['SIGN_IN', 'RANK'].includes(event) && setting.mode === 'BATTLE')}
                            />
                        ))}
                    </FlowBox>
                </div>
                <div style={{ flex: 1, overflow: 'hidden', height: '100%' }}>
                    {eventChildren[currentEvent] || ''}
                </div>
            </BgBox>

        )
    }
}

const { controlSelector } = selectors

const mapStateToProps = createStructuredSelector({
    matchInfo: rawJs(immutableGet(controlSelector, 'controlCurrentMatch')),
    signInData: rawJs(immutableGet(controlSelector, 'controlSignInData')),
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(NewControlPanel)